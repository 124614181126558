@mixin icon-hover($color) {
  i:hover {
    color: $color;
  }
}

.icons-list {
  & > div {
    text-align: center;
    margin-bottom: 32px;
  }
  i {
    font-weight: $font-normal;
    font-size: 18px;
    cursor: pointer;
  }

  &.primary {
    @include icon-hover($primary);
  }
  &.success {
    @include icon-hover($success);
  }
  &.warning {
    @include icon-hover($warning);
  }
  &.danger {
    @include icon-hover($danger);
  }
}

a.see-all-icons {
  float: right;
}

.awesomeIcons {
  height: 308px;
}

.kameleon-row {
  display: inline-block;
  min-width: 102px;
  width: 20%;
  .kameleon-icon {
    padding:0 10px;
    img {
      width: 81px;
    }
  }
}

@media (max-width: 750px) {
  .kameleon-row {
    width: 25%;
  }
}

@media (max-width: 550px) {
  .kameleon-row {
    width: 33%;
  }
}

@media (max-width: 430px) {
  .kameleon-row {
    width: 50%;
  }
}

.kameleon-icon-tabs {
  max-width: 84px;
  img {
    width: 100%;
    min-width: 81px;
    min-height: 81px;
  }
}

.kameleon-icon {
  text-align: center;
  margin: 0 auto;
  img {
    width: 100%;
  }
  span {
    display: block;
    text-align: center;
    white-space: nowrap;
  }
}

@mixin setImgBg($color) {
  img {
    background: $color;
  }
}

.with-round-bg {
  margin-bottom: 6px;
  img {
    border-radius: 50%;
    margin-bottom: 4px;
  }
  @include setImgBg($default);

  &.success {
    @include setImgBg($success);
  }
  &.danger {
    @include setImgBg($danger);
  }
  &.warning {
    @include setImgBg($warning);
  }
  &.info {
    @include setImgBg($info);
  }
  &.primary {
    @include setImgBg($primary);
  }
}


