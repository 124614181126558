.label {
  border-radius: 0;
}

.label-primary {
  background: $primary;
}

.label-info {
  background: $primary-light;
}

.label-success {
  background: $success;
}

.label-warning {
  background: $warning;
}

.label-danger {
  background: $danger;
}