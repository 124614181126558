input.task-todo {
  margin-bottom: 8px;
}

ul.todo-list {
  margin: 0;
  padding: 0;
  .placeholder, .ui-sortable-placeholder {
  }
  li {
    margin: 0 0 -1px 0;
    padding: 12px;
    list-style: none;
    position: relative;
    border: 1px solid $input-border;
    cursor: grab;
    height: 42px;
    i.remove-todo {
      position: absolute;
      cursor: pointer;
      top: 0px;
      right: 12px;
      font-size: 32px;
      transition: color 0.2s;
      color: rgba($input-border, 0.5);
      visibility: hidden;
      line-height: 42px;
      &:hover {
        color: $input-border;
      }
    }
    &:hover {
      i.remove-todo {
        visibility: visible;
      }
    }

    &.checked {
      .todo-text {
        color: $default-text;
      }
      &:before {
        background: $input-border !important;
      }
    }

    i.mark {
      display: block;
      position: absolute;
      top: -1px;
      left: -1px;
      height: 42px;
      min-width: 4px;
      background: $input-border;
      cursor: pointer;
      transition: min-width 0.3s ease-out;
    }

    &.active {
      i.mark {
        min-width: 40px;
      }
      label.todo-checkbox > span {
        &:before {
          color: white;
          content: '\f10c';
          margin-right: 20px;
          transition: margin-right 0.1s ease-out;
          transition-delay: 0.2s;
          float: none;
        }
      }
      label.todo-checkbox > input:checked + span:before {
        content: '\f00c';
      }
    }
  }
}

label.todo-checkbox {
  width: 100%;
  padding-right: 25px;
  min-height: 16px;
  cursor: pointer;
  > span {
    white-space: nowrap;
    height: 16px;
    &:before {
      border: none;
      color: $help-text;
      transition: all 0.15s ease-out;
    }
  }
}

.add-item-icon {
  display: none;
}

.task-todo-container.transparent {
  .todo-panel.panel {
    color: white;
    opacity: 0.9;
  }
  input.task-todo {
    color: white;
    width: calc(100% - 25px);
    border-radius: 0;
    border: none;
    background: transparent;
    &:focus {
      outline: none;
      background-color: transparent;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.12);
    }
  }
  .add-item-icon {
    display: block;
    float: right;
    margin-top: -45px;
    margin-right: 5px;
    font-size: 25px;
    cursor: pointer;
  }

  ul.todo-list {
    li {
      margin: 0;
      border: none;
      font-weight: $font-light;

      .blur-container{
        height: 40px;
        position: absolute;
        width: calc(100% + 40px);;
        top: 0;
        left: -25px;
        overflow-y: hidden;
      }
      &:hover{
        .blur-container{
          box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.12);
        }
        .blur-box {
          height: 100%;
          background: linear-gradient(to right, rgba(255,255,255,0.3) 0%,rgba(255,255,255,0) 100%);
          -webkit-filter: blur(3px);
        }
      }
      i.remove-todo {
        color: white;
        opacity: 0.4;
        &:hover {
          color: white;
          opacity: 0.95;
        }
      }
      i.mark {
        min-width: 40px;
        display: none;
      }
      label.todo-checkbox > span {
        &:before {
          position: absolute;
          color: $default-text;
          content: '\f10c';
          float: none;
          margin-right: 6px;
          transition: none;
        }
      }
      &.checked {
        label.todo-checkbox > span {
          &:before {
            content: '\f00c';
          }
        }
      }
    }
  }

  .box-shadow-border{
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.12);
    width: calc(100% + 44px);
    margin-left: -22px;
  }

}