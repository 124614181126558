.tree-node{
  line-height: 25px;
  cursor: pointer;
  &.selected{
    background-color: $border-light ;
  }
  .control{
    cursor: pointer;
    font-size: 16px;
    padding-left: 5px;
  }
  &:hover{
    background-color: $input-border;
  }
}

.jstree-default a.jstree-clicked, .jstree-default a.jstree-hovered {
  background-color: rgba(0, 0, 0, 0.25);
}

.jstree-default a.jstree-anchor, .jstree-default a.jstree-wholerow{
  &:hover{
    background-color: rgba(0, 0, 0, 0.15);
  }
}

.control-side > div {
  margin-top: 5px;
  margin-bottom: 10px;

  .btn{
    width: 100px;
  }
}

#tree-root{
  border-left: 1px solid $border-light;
  padding-left: 10px;
}

.tree-panel{
  height: 500px;
}

.search-container{
  margin-top: 10px;
}