.widgets {
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
}

.full-width {
  margin: 0 -22px -16px;
}

.horizontal-scroll {
  overflow-x: auto;
  overflow-y: visible;
}

.panel-subtitle {
  font-size: 15px;
  margin-bottom: 16px;
}

.panel-nav {
  position: absolute;
  top: 12px;
  right: 15px;
  z-index: 2;

  i {
    cursor: pointer;
    font-size: 14px;
    color: #bfbfbf;
    margin-left: 11px;
    display: inline-block;

    &.fa-minus {
      margin-bottom: -1px;
      vertical-align: bottom;
    }

    &:hover {
      color: $default-text;
    }
  }
}