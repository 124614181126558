.letter-layout {
  margin-top: -15px;
  margin-right: -22px;
}

.mail-panel {
  &.panel > .panel-body {
    padding-left: 0;
  }
}

.mail-navigation-container {
  float: left;
  position: relative;
  height: 550px;
  transition: width 0.5s;
  color: $default-text;
  width: 200px;
  overflow: hidden;
  padding: 0;
  .mail-navigation {
    cursor: pointer;
    font-weight: $font-light;
    font-size: 16px;
    text-align: left;
    padding: 10px 10px 10px 30px;
    margin-left: 0;
    transition: 0.8s padding ease;
    .new-mails {
      position: absolute;
      left: 150px;
      padding: 1px 6px;
      border: 1px solid;
      background-color: transparent;
      margin-top: -2px;
      font-size: 12px;
      margin-right: 5px;
      border-radius: 10px;
      transition: 0.8s left ease;
    }
    &.active {
      background: rgba(black, 0.2);
      color: white;
      transition: background-color .5s ease;
      &:hover {
        background-color: rgba(black, 0.3);
      }
      .new-mails {
        background-color: transparent;
      }
    }
    &:hover {
      background-color: rgba(black, 0.1);
    }
  }
  .btn.compose-button {
    width: 140px;
    font-weight: $font-light;
    border: $border 2px solid;
    background-color: transparent;
    margin: 15px 0 ;
  }
}

.labels {
  margin-top: 14px;
}

.labels-container {
  margin-top: 16px;
  text-align: center;
  padding-right: 28px;
  padding-left: 20px;
  .label-item {
    display: inline-block;
  }
}

.labels-title {
  padding-left: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.12);
  .label-header {
    line-height: 24px;
  }
}

.label-item {
  margin: 5px;
}

.add-label-container {
  margin-top: 10px;
  text-align: center;
  font-size: 16px;
  font-weight: $font-light;
  .label-input-stub {
    font-size: 14px;
    margin-left: 5px;
  }
  i {
    cursor: pointer;
  }
}

.margin-left {
  margin-left: 10px;
}

.mail-messages-control {
  padding: 10px;

  ul.dropdown-menu {
    margin-top: 5px;
  }

  .btn {
    background-color: transparent;
    width: auto;
    &:focus, &:hover, &:active {
      color: $default-text;
      opacity: 0.8;
      box-shadow: none;
    }
  }

  .more-button {
    width: 60px;
    font-size: 14px;
    padding: 6px 5px;
  }

  .refresh-button {
    margin-left: 10px;
  }

  span.select-all-label {
    font-size: 13px;
    font-weight: $font-light;
  }
}

.message-container, .side-message-navigation {
  float: left;
  padding: 0 15px;
  position: relative;
  height: 550px;
  transition: width 0.5s;
  width: calc(100% - 200px);
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: -1px 0 0 0 rgba(255, 255, 255, 0.12);
}

.side-message-navigation {
  padding: 0;
  width: calc(100% - 200px);
  .side-message-navigation-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
}

.mail-body-part {
  width: calc(100% - 30px);
  text-overflow: ellipsis;
  height: 16px;
  overflow: hidden;
  margin-top: 3px;
  margin-left: 5px;
  opacity: .8;
}

.mail-tag.tag.label {
  display: inline-block;
  font-size: 14px;
  text-transform: uppercase;
  width: 65px;
}

.phone-email {
  i {
    color: $default-text;
  }
}

.message-container {
  padding: 15px 15px 10px 15px;
  overflow-x: hidden;
}

.little-human {
  cursor: pointer;
  transition: border-left 1.5s ease;
  font-weight: 300;
  .little-human-picture {
    width: 45px;
    height: 45px;
    border-radius: 23px;
    margin: 7px 0px 7px 7px;
    transition: all .2s ease-in-out;
  }
  .name {
    font-size: 14px;
    vertical-align: super;
    white-space: nowrap;
  }
  .name-wrap {
    margin-left: 10px;
  }
  .date {
    float: right;
    margin: 10px 10px 0 5px;
    display: inline-block;
    font-size: 13px;
    white-space: nowrap;
  }
  .tag {
    vertical-align: super;
  }
  .subject {
    white-space: nowrap;
    margin: 0 30px;
    font-size: 16px;
  }
  &:hover {
    .little-human-picture {
      animation: rotateReturnAnimation 0.5s;
    }
    background-color: rgba(255, 255, 255, 0.1);
  }
}

.back-button {
  margin-left: 45px;
}

.name-container {
  margin-left: 10px;
}

.mail-checkbox {
  margin-top: 5px;
  margin-left: 6px;
  .custom-checkbox {
    margin-left: 5px;
  }
}

.mail-panel .tag {
  text-transform: lowercase;
  font-size: 11px;
  font-weight: $font-light;
  width: 45px;
  cursor: pointer;
  display: inline-block;
  &.label {
    padding: .2em .5em;
    border-radius: 2px;
    line-height: 1.1;
  }
  &.work {
    background-color: $primary;
  }

  &.study {
    background-color: $warning;
  }

  &.family {
    background-color: $success;
  }

  &.friend {
    background-color: $danger;
  }
}

.messages {
  overflow: auto;
  table {
    width: 100%;
  }
}

@media screen and (min-width: 1199px) {
  .photo-td {
    width: 55px;
  }
  .check-td {
    width: 35px;
  }
}

.important {
  color: $warning;
}

.message-control-icon {
  cursor: pointer;
  font-size: 16px;
  margin-right: 5px;
}

.person-info {
  padding: 0 0 10px 45px;
  margin-top: 10px;
  .human-picture {
    width: 80px;
    height: 80px;
    border-radius: 40px;
    margin-top: -65px;
  }

  .name {
    display: inline-block;
    margin-left: 10px;
    margin-top: 5px;
    h2 {
      font-weight: 300;
      margin-bottom: 0;
      font-size: 24px;
    }
    .second-name {
      margin: 5px 0;
    }
  }

}

.no-padding {
  padding: 0;
}

.contact-info {
  display: inline-block;
  div {
    margin-top: 10px;
  }
  &.phone-email {
    .ion-iphone {
      font-size: 32px;
      width: 27px;
      display: inline-block;
    }
    .phone {
      font-size: 19px;
      font-weight: $font-light;
      vertical-align: super;
    }
    .ion-email {
      font-size: 24px;
      width: 27px;
      display: inline-block;
    }
    .email {
      font-size: 16px;
      font-weight: $font-light;
      vertical-align: super;
    }
  }

  &.position-address {
    div {
      margin-top: 17px;
    }
    .position {
      font-size: 20px;
      font-weight: $font-light;
    }

    .address {
      font-size: 16px;
      font-weight: $font-light;
    }
  }
}

.message-details {
  margin: 5px 0;
  padding: 5px 0 5px 30px;
  .subject {
    font-size: 20px;
    margin-right: 10px;
    font-weight: $font-light;
  }
  .control-icons {
    float: right;
    font-size: 20px;
    margin-right: 40px;
    i {
      cursor: pointer;
    }
  }
  .date {
    white-space: nowrap;
    font-size: 12px;
  }
}

.message-body {
  margin: 20px 0;
  padding-left: 30px;
  font-weight: $font-light;
  line-height: 18px;
  p {
    margin-bottom: 0;
  }
}

.attachment {
  margin: 5px 0;
  padding-left: 30px;
  .file-icon {
    font-size: 24px;
    cursor: pointer;
  }

  .file-name {
    font-size: 14px;
    margin-left: 3px;
    position: relative;
    top: -3px;
    font-weight: $font-light;
  }

  .file-links {
    font-size: 14px;
  }
}

.line {
  height: 1px;
  background-color: $input-border;
  opacity: 0.3;
}

.answer-button {
  margin-left: 20px;
  color: white;
  font-size: 16px;
  font-weight: $font-light;
  padding-left: 40px;
  padding-right: 40px;
  background-color: $dribble-color;
  &:hover {
    color: white;
  }
  &:focus {
    color: white;
  }
}

.answer-container {
  float: right;
  margin-top: 10px;
  .btn {
    margin-top: 3px;
    background-color: $primary-light;
    border: none;
    color: white;
    width: 100px;
    transition: none;
    &:hover {
      transform: none;
      background-color: $danger;
    }
  }
}

@keyframes rotateReturnAnimation {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@media screen and (max-width: 1199px) {
  .name-h {
    display: inline;
  }
  .person-info {
    .human-picture {
      margin-top: -35px;
    }
    .name {
      margin-top: 20px;
    }
    .mail-tag {
      margin-top: 10px;
    }
  }
  .second-name {
    margin-top: 0;
  }
}

@media screen and (max-width: 990px) {

  .person-info .human-picture {
    width: 150px;
    height: 150px;
    border-radius: 75px;
    margin: 5px auto;
    display: block;

  }

  .person-info, .message-details, .message-body, .attachment {
    padding-left: 10px;
  }

  .message-container {
    padding-left: 15px;
  }

  .back-button, .contact-info {
    margin-left: 15px;
  }

  .additional-info {
    display: none;
  }

  .little-human .date {
    font-size: 10px;
  }

  .margin-left {
    margin-left: 5px;
  }

}

@media screen and (max-width: 760px) {

  .mail-navigation-container {
    width: 175px;
    .mail-navigation {
      padding-left: 19px;
      .new-mails {
        left: 137px;
      }
    }
  }

  .side-message-navigation {
    width: calc(100% - 175px);
  }

  .message-container {
    width: calc(100% - 175px);
  }

  .mail-body-part {
    display: none;
  }
  .little-human .little-human-picture {
    width: 30px;
    height: 30px;
  }
  .messages-control .more-button {
    width: 50px;
  }
}

@media screen and (max-width: 560px) {
  .mail-navigation-container {
    padding: 0;
    width: 0;
    &.expanded {
      width: 155px;
    }
    .mail-navigation {
      font-size: 14px;
      padding-left: 23px;
      .new-mails {
        left: 116px;
        padding: 0px 5px;
        margin-top: 0px;
        font-size: 12px;
      }
      .labels {
        margin-left: 10px;
      }
    }
    .compose-button {
      width: 110px;
    }
  }

  .mail-messages-control {
    .custom-checkbox {
      margin-left: 5px;
      margin-top: -2px;
    }
  }

  .side-message-navigation, .message-container {
    &.expanded {
      width: 100%;
      border: none;
      box-shadow: none;
    }
    width: calc(100% - 155px);
  }

  div.toggle-navigation-container {
    display: inline-block;
  }

  .little-human {
    .name {
      white-space: inherit;
    }
    .little-human-picture {
      display: none;
    }
  }

  .add-label-container {
    padding-left: 0;
    font-size: 13px;
    font-weight: $font-light;
  }

}

.compose-header {
  padding: 8px 3px 8px 10px;
  color: white;
  font-weight: $font-light;
  font-size: 13px;
  background-color: $github-color;
}

.header-controls {
  display: inline-block;
  float: right;
  i {
    margin-right: 5px;
    font-size: 14px;
    cursor: pointer;
    &:hover {
      color: $primary-light;
    }
  }
}

.compose-container .ta-scroll-window > .ta-bind {
  height: 290px;
  overflow-y: scroll;

}

.modal-compose input.form-control.compose-input {
  background-color: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, .2);
  border-radius: 0;
}

.compose-footer {
  padding: 2px 4px;
  background-color: $mail-box;
  .btn-send {
    background-color: $primary-light;
    color: white;
    padding: 2px 10px;
    margin: 3px;
    font-weight: $font-light;
  }
  .btn-default {
    background-color: transparent;
    border: none;
  }
}

.footer-controls {
  float: right;
  margin: 6px;
}

.compose-footer-icon {
  font-size: 18px;
  margin-right: 5px;
  cursor: pointer;
  color: $dropdown-text;
  &:hover {
    color: $primary-light;
  }
}

.compose-container {
  background-color: $mail-box;
  .ta-text.ta-editor {
    background-color: $input-border;
    div {
      &:focus {
        outline: none;
      }
    }
  }

}

.footer-control-first {
  border-right: 2px solid $help-text;
  display: inline-block;
  padding-right: 9px;
}

.toggle-navigation-container {
  display: none;
  vertical-align: middle;
  margin-left: -1px;
  &.detail-page {
    margin-left: 10px;
  }
}

.collapse-navigation-link {
  font-size: 32px;
  color: $default-text;
  &:visited {
    color: $default-text;
  }
  &:hover {
    color: $warning;
    opacity: .8;
  }

}

.collapsed {
  width: 0;
}

.modal .modal-dialog.modal-compose {
  max-width: 398px;
  position: fixed;
  bottom: 0;
  right: 0;
  max-height: 474px;

  .form-control, .bootstrap-tagsinput input {
    @include placeholderStyle($dropdown-text, 1);
    color: $dropdown-text;
    border-radius: 0;
  }
  .ta-toolbar {
    .btn {
      border-radius: 0;
      color: $dropdown-text;
      border: 1px transparent;
      &.active {
        box-shadow: none;
        background-color: rgba(0, 0, 0, .12);
      }
    }
  }
  @include overrideColors($dropdown-text)
}

@media (max-width: 640px) {
  .modal .modal-dialog.modal-compose {
    max-height: 600px;
  }
}