.feed-panel .panel-body{
  padding: 10px 0;
}

.feed-message {
  $text-message-color: $default-text;
  $video-message-color: $danger;
  $image-message-color: $success;
  $geo-message-color: $primary;
  padding: 10px 0 ;
  border-bottom: 1px solid rgba(0,0,0,0.12);
  box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.12);
  &:first-child {
    padding-top: 0;
  }

  .message-icon {
    cursor: pointer;
    width: 60px;
    height: 60px;
    float: left;
    position: relative;
    margin-left: 20px;
    > img, .media-icon {
      border-radius: 30px;
      width: 100%;
      height: 100%;
    }

    .sub-photo-icon {
      display: inline-block;
      padding: 4px;
      &:after {
        content: '';
        display: inline-block;
        width: 22px;
        height: 22px;
        background-size: contain;
      }
      &.video-message {
        background: $video-message-color;
        &:after {
          @include bg-image('theme/icon/feed/feed-video.svg');
        }
      }
      &.image-message {
        background: $image-message-color;
        &:after {
          width: 21px;
          height: 21px;
          margin-top: 1px;
          margin-left: 1px;
          border-radius: 5px;
          @include bg-image('theme/icon/feed/feed-image.svg');
        }
      }
      &.geo-message {
        background: $geo-message-color;
        &:after {
          width: 22px;
          height: 22px;
          @include bg-image('theme/icon/feed/feed-location.svg');
        }
      }
    }

    .sub-photo-icon {
      position: absolute;
      width: 30px;
      height: 30px;
      right: -2px;
      bottom: -4px;
      border-radius: 15px;
    }
  }

  .text-block {
    cursor: pointer;
    position: relative;
    border-radius: 5px;
    margin: 0 0 0 80px;
    padding: 5px 20px;
    color: $default-text;
    width: 280px;
    height: 70px;

    &.text-message {
      font-size: 12px;
      width: inherit;
      max-width: calc(100% - 80px);
      height: inherit;
      min-height: 60px;
      &:before {
        display: block;
      }
      .message-content {
        font-size: 12px;
        line-height: 15px;
        font-weight: $font-light;
      }
    }
    &.small-message {
      width: 155px;
      height: 145px;
      .preview {
        bottom: 0;
        top: initial;
        height: 87px;
        img {
          width: 155px;
          height: 87px;
          border-radius: 0 0 5px 5px;
        }
      }
    }
  }

  .message-header {
    font-size: 12px;
    padding-bottom: 5px;
    .author {
      font-size: 13px;
      padding-right: 5px;
    }
  }

  .message-content {
    font-size: 18px;
    line-height: 20px;
  }

  .preview {
    transition: 0s linear all;
    display: inline-block;
    img {
      padding-top: 10px;
      width: 100%;
      height: auto;
      float: none!important;
    }
  }

  .message-time {
    width: 100%;
    left: 0;
    font-size: 11px;
    padding-top: 10px;
    color: $help-text;
    margin-bottom: 5px;
    .post-time {
      float: left;
    }
    .ago-time {
      float: right;
    }
  }
}



.line-clamp
{
  display            : block;
  display            : -webkit-box;
  -webkit-box-orient : vertical;
  position           : relative;

  line-height        : 1.2;
  overflow           : hidden;
  text-overflow      : ellipsis;
  padding            : 0 !important;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  .line-clamp:after
  {
    content    : '...';
    text-align : right;
    bottom     : 0;
    right      : 0;
    width      : 25%;
    display    : block;
    position   : absolute;
    height     : calc(1em * 1.2);
  }
}


@supports (-webkit-line-clamp: 1)
{
  .line-clamp:after
  {
    display : none !important;
  }
}
.line-clamp-1
{
  -webkit-line-clamp : 1;
  height             : calc(1em * 1.2 * 1);
}
.line-clamp-2
{
  -webkit-line-clamp : 2;
  height             : calc(1em * 1.2 * 2);
}
.line-clamp-3
{
  -webkit-line-clamp : 3;
  height             : calc(1em * 1.2 * 3);
}
.line-clamp-4
{
  -webkit-line-clamp : 4;
  height             : calc(1em * 1.2 * 4);
}
.line-clamp-5
{
  -webkit-line-clamp : 5;
  height             : calc(1em * 1.2 * 5);
}
