.control {
  margin-bottom: 10px;
  .form-control {
    width: 75%;
  }
  span {
    white-space: pre-line;
  }
}

.notification-panel {
  .control,
  .radio,
  label,
  label.custom-checkbox > span,
  label.custom-radio > span {
    font-weight: $font-light;
  }
}

.radio-controls .custom-radio{
    margin-top: 5px;
}

@media (max-width: 991px) {
  .toastr-radio-setup {
    margin-left: 22px;
  }
}

.radio-header {

  margin-bottom: 0;
  &.position-header {
    margin-top: 15px;
  }
}

.button-row {
  line-height: 37px;
  button {
    width: 125px;
  }
}

.result-toastr {
  border-radius: 5px;
  color: rgba(255, 255, 255, 0.9);
  background-color: rgba(0, 0, 0, 0.33);
  border: none;
}

.sub-label {
  margin-top: 5px;
}

.toast-title {
  font-weight: $font-normal;
}

.toast-message {
  font-weight: $font-light;
}

#toast-container.toast-top-center, #toast-container.toast-bottom-center{
  .toast{
    margin-bottom: 5px;
  }
}
