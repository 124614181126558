$height: 52px;

.back-top {
  width: $height;
  height: $height;
  cursor: pointer;
  z-index: 9999;
  display: none;
  text-decoration: none;
  right: (180px - $height) / 2;
  bottom: 40px !important;
  font-size: 45px;
  text-align: center;
  opacity: 0.4;
  color: $primary;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 50%;
  line-height: 46px;
  &:hover {
    opacity: 0.8;
  }
}
